import React from 'react';
import PropTypes from 'prop-types';
import { Link, StaticQuery, graphql } from 'gatsby';

import best from '../images/best.jpeg';
import bodywork1 from '../images/bodywork1.jpeg';
import bodywork2 from '../images/bodywork2.jpeg';
import dario2 from '../images/dario2.jpeg'

import Cta from './cta';
import Waves from './waves';
import Navigation from './navigation';
import Footer from './footer';

import '../css/typography.css';

function Layout({ children }) {
    return (
        <StaticQuery
            query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title,
              github,
              blog
            }
          }
        }
      `}
            render={data => (
                <body className="leading-normal tracking-normal text-white gradient">
                    {children}
                    <Navigation />

                    {/* Hero */}
                    <div className="pt-40 hero">
                        <div className="container px-3 mx-auto flex flex-wrap flex-col md:flex-row items-center">
                            <div className="tenor flex pb-12 flex-col w-full md:w-2/5 justify-center items-center sm:items-start text-center md:text-left">
                                <h1 className="my-4 text-3xl sm:text-4xl font-bold leading-tight">Bodywork designed<br /> to make your body<br /><span className="highlight">work.</span></h1>
                                <div className="flex">
                                    <Link to="/booking" className="quicksand mx-auto hidden md:block md:mx-0 bg-white text-gray-800 font-bold rounded-full my-6 py-4 px-8 shadow-lg" href="#">
                                        Book&nbsp;Now
                                    </Link>
                                    <Link to="/treatments" className="quicksand mx-auto ml-4 bg-white text-gray-800 font-bold rounded-full my-6 ml-2 py-4 px-8 shadow-lg" href="#">
                                        Treatments
                                    </Link>
                                    <Link to="/giftcard" className="quicksand mx-auto ml-4 bg-white text-gray-800 font-bold rounded-full my-6 ml-2 py-4 px-8 shadow-lg" href="#">
                                        Gift&nbsp;Cards
                                    </Link>
                            </div>
                            </div>
                        </div>
                        <Waves />
                    </div>

                    {/* Main Blurbs */}
                    <section className="bg-white border-b pt-8">
                        <div className="container max-w-5xl mx-auto">

                            <div className="flex flex-wrap justify-center py-10 md:py-20">
                                <div className="w-full md:w-1/2 px-8">
                                    <h3 className="text-xl sm:text-3xl text-gray-800 font-bold mb-3">Targeted Pain Relief for Peak Performance and Ultimate Relaxation</h3>
                                    <p className="text-gray-600 mb-4">Mexía Bodywork is THE total physique solution designed to restore youthful movement, energy, and well-being. Utilizing the world’s most advanced  techniques, Dario Mexia heals pain at its root and rejuvenates your body.</p>
                                    <Link to="/treatments" className="text-blue-500 mb-4 inline-block" href="#">Look at our available treatments.</Link>
                                </div>
                                <div className="w-full md:w-1/2 px-8">
                                    <img className="imageFilter rounded-lg" src={bodywork1} />
                                </div>
                            </div>

                            <div className="flex flex-wrap justify-center py-10 md:py-20">
                                <div className="w-full md:w-1/2 px-8 mb-4">
                                    <img className="imageFilter rounded-lg" src={bodywork2} />
                                </div>
                                <div className="w-full md:w-1/2 px-8">
                                    <div className="align-middle">
                                        <h3 className="text-xl sm:text-3xl text-gray-800 font-bold mb-3">Body Work Designed To Make Your Body Work</h3>
                                        <p className="text-gray-600 mb-8">Bodywork is a transformative form of physical care that synthesizes the best of massage therapy, healing cupping and assisted stretching exercises to give your body the relief it needs to perform at its absolute best.
                                            It’s more than just another trip to the massage parlor. It’s a total body transformation for relief that lasts.</p>
                                        <Link to="/treatments" className="text-blue-500" href="#">Learn about the benefits of bodywork.</Link>
                                    </div>
                                </div>
                            </div>

                            <div className="flex flex-wrap justify-center py-10 md:py-20">
                                <div className="w-full md:w-1/2 px-8">
                                    <h3 className="text-xl sm:text-3xl text-gray-800 font-bold mb-3">Best of DC 2024</h3>
                                    <p className="text-gray-600 mb-4">Mexía Bodywork was recently voted Best of DC for "Best Therapeutic Massage". Thanks to the DMV community for supporting our small business and allowing us to provide compassionate, best-in-class service.</p>
                                    <Link to="https://bestof2024.washingtoncitypaper.com/goods-and-services-readers-picks/best-therapeutic-massage" target="_blank" className="text-blue-500" href="https://bestof2024.washingtoncitypaper.com/goods-and-services-readers-picks/best-therapeutic-massage">Best of DC 2024</Link>
                                </div>
                                <div className="w-full md:w-1/2 px-8">
                                    <img className="imageFilter rounded-lg" src={best} />
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* Reviews */}
                    <section className="bg-white border-b pt-8 px-8 md:px-0">
              
                        <div className="container mx-auto flex flex-wrap pt-4 pb-12">
                            <h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Latest Reviews</h1>
                            <div className="w-full mb-4">
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                            </div>
                            <div className="w-full mt-12">
                                <div className="elfsight-app-5e11d1e3-983a-4da6-9dbe-5c87b1cdaa58"></div>
                            </div>
                        </div>

                    </section>

                    {/* Mission */}
                    <section className="bg-gray-100 py-8">

                        <div className="container max-w-5xl mx-auto px-2 py-4 text-gray-800">
                            <h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Our Cause & Mission</h1>
                            <div className="w-full mb-4">
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                            </div>

                            <div className="flex flex-col md:flex-row justify-center items-center sm:my-4">
                                <div className="w-full md:w-1/2 p-6">
                                    <p>
                                        Here at Mexia Massage and Bodywork, our cause is to enable people to experience an ever greater comfort in their own bodies, where well-being is restored, nurtured, and sustained indefinitely.
                                    </p>
                                    <br />
                                    <p>
                                        Our goal is to help you move better, feel better, and live pain free. You are the reason we continue to learn and refine our craft, always striving to provide the most effective and advanced care possible. Through Mexia Massage & Bodywork, we create personalized treatment plans tailored to your unique needs so you can get back to doing what you love, stronger than ever.
                                    </p>
                                </div>
                            </div>
                        </div>

                    </section>

                    {/* About section */}
                    <section className="bg-gray-100 py-8">
              
                        <div className="container max-w-5xl mx-auto px-2 py-4 text-gray-800">
                            <h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Your Specialist</h1>
                            <div className="w-full mb-4">
                                <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                            </div>

                            <div className="flex flex-col md:flex-row justify-center items-center sm:my-4">
                                <div className="md:w-1/2 p-6">
                                    <img className="imageFilter rounded-lg mx-auto max-h-96" src={dario2} alt="Dario Mexia" />
                                </div>
                                <div className="w-full md:w-1/2 p-6">
                                    <p>
                                        Dario Mexia is not your average massage therapist. His last name, "Mexia," comes from an old Spanish variant meaning "medicine" or "healer" , a fitting legacy that fuels his passion for helping others move, feel, and perform at their best.
                                    </p>
                                    <br />
                                    <p>
                                        Originally from Colombia, Dario earned a Bachelor's degree in Economics before moving to the U.S. in 2012. However, his true calling led him to the world of manual therapy and bodywork. After completing a massage therapy program and becoming a certified personal trainer, he has continued to refine his craft through advanced training in Structural Integration, Myofascial Release, Fascia Stretch Therapy , Cupping Therapy (a.k.a Myofascial Decompression), Scraping Therapy, Sports Massage and Medical Massage.
                                    </p>
                                    <br />
                                    <p>
                                        As a former semi-professional volleyball player, Dario understands the demands placed on the body whether you're an athlete, an active professional, or someone dealing with chronic tension. His approach goes beyond relaxation; he specializes in restoring mobility, relieving pain, and optimizing performance through customized, hands-on techniques.
                                    </p>
                                </div>
                            </div>
                        </div>
                
                    </section>

                    {/* Instagram */}
                    <section className="bg-white py-8">
                        <h1 className="w-full my-2 text-3xl font-bold leading-tight text-center text-gray-800">Instagram</h1>
                        <div className="w-full mb-4">
                            <div className="h-1 mx-auto gradient w-64 opacity-25 my-0 py-0 rounded-t" />
                        </div>
                        <div className="container max-w-5xl mx-auto px-2 pt-4 text-gray-800">
                            <div className="elfsight-app-b25e1d9e-e0e8-4786-86be-aa1e66d3a735"></div>
                        </div>
                    </section>

                    <Cta />
                    <Footer />

                </body>
            )}
        />
    );
}

Layout.propTypes = {
    children: PropTypes.node.isRequired
};

export default Layout;
